import {useContext} from 'react'
import {UserContext} from '../contexts'

function useUser() {
  const user = useContext(UserContext)

  return user
}

export {useUser}
