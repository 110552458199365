import {forwardRef} from 'react'
import styles from './Panel.module.css'

export const Panel = forwardRef(function Panel(
  {
    classes = '',
    padding = '2rem',
    fullWidth = false,
    fullHeight = false,
    glowing = false,
    topFocus = false,
    onlyBorder = false,
    children,
  },
  ref
) {
  return (
    <div
      ref={ref}
      style={{
        padding: padding,
        width: fullWidth ? '100%' : 'auto',
        height: fullHeight ? '100%' : 'auto',
      }}
      className={`${styles.panel} ${classes} ${
        glowing && styles.panelGlowing
      } ${topFocus && styles.panelTopFocus} ${
        onlyBorder && styles.panelOnlyBorder
      }`}
    >
      {children}
    </div>
  )
})
