import React, {useContext} from 'react'
import Link from 'next/link'
import {UserContext} from './contexts'
import {ProBadge} from './ProBadge'
import {Loader} from './Loader'
import {Stack} from './Stack'
import {Icon} from './Icon'
import {cdnifyStorageUrl} from './utils'

const getSmallAvatarUrl = (a) => a

export function AvatarImage({
  url,
  userId,
  username,
  size = 24,
  small,
  isPro,
  useCdnUrl,
  ...props
}) {
  const user = useContext(UserContext)

  let imgUrl = url || (user && userId === user.uid ? user.photoURL : '')

  imgUrl = useCdnUrl && imgUrl ? cdnifyStorageUrl(url) : url

  if (!imgUrl) {
    imgUrl =
      userId && !isNaN(parseFloat(userId.slice(-1)))
        ? '/images/catto-2.png'
        : '/images/catto-1.png'
  } else {
    if (small) {
      imgUrl = getSmallAvatarUrl(url)
    }
  }
  return (
    <div style={{position: 'relative'}}>
      <div
        className={`avatar-link__image ${
          user && userId === user.uid ? 'avatar-link__image--self' : ''
        } ${isPro ? 'avatar-link__image--pro' : ''}`}
      >
        <img
          src={imgUrl}
          alt={username ? `${username} avatar` : ''}
          {...props}
          width={size}
          height={size}
        />
      </div>
      {isPro && (
        <div className="avatar-link__probadge-wrap">
          {' '}
          <ProBadge height="15" />
        </div>
      )}
    </div>
  )
}
export function Avatar({
  url,
  userId,
  username,
  size = 24,
  small,
  isPro,
  useCdnUrl,
  ...props
}) {
  return (
    <Link href={`/player/${username || userId}`}>
      <a
        className="avatar-link link hint--top"
        aria-label={username ? `@${username}` : ''}
      >
        <AvatarImage
          url={url}
          userId={userId}
          username={username}
          size={size}
          small={small}
          isPro={isPro}
          useCdnUrl={useCdnUrl}
          {...props}
        />
      </a>
    </Link>
  )
}

const playerCrownColors = [
  'var(--clr-gold-crown)',
  'var(--clr-silver-crown)',
  'var(--clr-bronze-crown)',
]
export function PlayerName({
  name,
  userId,
  username,
  className = '',
  isPro,
  rank,
  ellipsis,
}) {
  const user = useContext(UserContext)
  const inner = (
    <span
      className={`${className} ${
        user && userId === user.uid ? 'append-star' : ''
      }`}
    >
      {name}
    </span>
  )

  return (
    <div
      style={{
        display: ellipsis ? 'flex' : 'inline-block',
        gap: '0.25rem',
        alignItems: 'center',
      }}
    >
      {rank <= 3 ? (
        <Icon
          name="crown"
          size="1em"
          color={playerCrownColors[rank - 1]}
          marginRight="0"
        />
      ) : null}
      <Link href={`/player/${username || userId}`}>
        <a className="name-link">{inner}</a>
      </Link>
      {/* {isPro && (
        <Link href="/plus">
          <a style={{margin: '0 0.4rem', display: 'flex'}}>
            <ProBadge height="15" />
          </a>
        </Link>
      )} */}
    </div>
  )
}

export function Input({label, help, fullWidth = false, ...props}) {
  const id = `input-${~~(Math.random() * 1000)}`
  return (
    <div
      className={`input-container ${
        fullWidth ? 'input-container--full-width' : ''
      }`}
    >
      <label htmlFor={id}>{label}</label>
      <input id={id} {...props} />
      {help ? <p className="input-hint">{help}</p> : null}
    </div>
  )
}

export function Checkbox({
  label,
  value,
  help,
  noMargin = false,
  fullWidth = false,
  ...props
}) {
  return (
    <div
      className={`input-container ${
        fullWidth ? 'input-container--full-width' : ''
      } ${noMargin ? 'input-container--no-margin' : ''}`}
    >
      <label>
        <input type="checkbox" checked={value} {...props} /> {label}
      </label>
      {help ? <p className="input-hint">{help}</p> : null}
    </div>
  )
}

export function TextArea({label, id, ...props}) {
  return (
    <div className="input-container">
      <label htmlFor={id}>{label}</label>
      <textarea id={id} {...props} />
    </div>
  )
}

export function ButtonGroup({children, noPadding, center}) {
  return (
    <div
      className={`btn-group ${center ? 'btn-group--center' : ''}  ${
        noPadding ? 'btn-group--nopadding' : ''
      } `}
    >
      {children}
    </div>
  )
}

export function ComponentFallbackLoader() {
  return (
    <div style={{padding: '1rem'}}>
      <Stack gap="5px">
        <Loader />
        <span>Loading...</span>
      </Stack>
    </div>
  )
}

export function Box({tag = 'div', inline, style, className, children}) {
  const Tag = tag
  if (inline) {
    style.display = 'inline-block'
  }
  return (
    <Tag style={style} className={className}>
      {children}
    </Tag>
  )
}

export function ItemId({id}) {
  if (!id) return ''
  return id.length > 5 ? '' : `#${id}`
}

export function Emoji({label, children}) {
  return (
    <span role="img" aria-label={label}>
      {children}
    </span>
  )
}

export function Separator({vertical = false}) {
  return (
    <div className={`separator ${vertical && 'separator--vertical'}`}></div>
  )
}
