import * as React from 'react'

function Stack({
  gap = '1rem',
  align = 'center',
  justify = 'flex-start',
  children,
}) {
  return (
    <div
      style={{
        display: 'flex',
        gap: gap,
        alignItems: align,
        justifyContent: justify,
      }}
    >
      {children}
    </div>
  )
}

export {Stack}
