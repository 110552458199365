const ProBadge = ({height = 18, style}) => (
  <img
    height={height}
    width={~~((height * 37) / 18)}
    className="pro-badge"
    style={style}
    src="/images/pro-badge.svg"
    alt="Pro badge"
  />
)

export {ProBadge}
