import {useEffect, useState, useRef} from 'react'

function getDigits(n) {
  return n.split('')
}

const Number = ({value, nextValue}) => {
  const el = useRef()

  useEffect(() => {
    if (nextValue === value) return
    // console.log({value, nextValue})
    el.current?.classList.remove('changed')

    setTimeout(() => {
      el.current?.classList.add('changing')
    }, 20)

    setTimeout(() => {
      el.current?.classList.add('changed')
      el.current?.classList.remove('changing')
    }, 950)
  }, [nextValue])

  return (
    <div ref={el} className="count up">
      <span className="current top ">{value}</span>
      <span className="next top ">{nextValue}</span>
      <span className="current bottom">{value}</span>
      <span className="next bottom">{nextValue}</span>
    </div>
  )
}

export const AnimatedNumber = ({number, countDigits = 3, size = '0.6'}) => {
  const [numbers, setNumbers] = useState(Array(4).fill(countDigits))
  const [lastNumbers, setLastNumbers] = useState(Array(4).fill(countDigits))

  useEffect(() => {
    const arr = getDigits((number + '').padStart(countDigits, '0'))

    setLastNumbers([...numbers])
    setNumbers(arr)
  }, [number])

  return (
    <div
      style={{
        display: 'inline-flex',
        gap: 'calc(1rem * var(--size))',
        '--size': size,
      }}
    >
      {numbers.map((n, index) => (
        <Number key={index} value={lastNumbers[index]} nextValue={n} />
      ))}
    </div>
  )
}
