import mixpanel from 'mixpanel-browser'

const TRACKING_ID = 'UA-136774468-1'

export const sendPageView = () => {
  if (window.location.href.match(/apiKey/)) {
    return
  }
  // Wait for document title to be set
  setTimeout(() => {
    window.gtag('config', TRACKING_ID, {
      page_location: window.location.href,
    })
  }, 1000)
}

export const sendEvent = ({name, category, label}) => {
  window.gtag &&
    window.gtag('event', name, {
      event_category: category,
      event_label: label,
    })
  mixpanel.track(name, {
    category,
    label,
  })
}
