import {forwardRef} from 'react'

const Stack = forwardRef(function Stack(
  {
    classes = '',
    gap = '1rem',
    align = 'center',
    justify = 'flex-start',
    direction = 'horizontal',
    fullWidth = false,
    fullHeight = false,
    wrap,
    children,
  },
  ref
) {
  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        gap: gap,
        alignItems: align,
        justifyContent: justify,
        flexDirection: direction === 'vertical' ? 'column' : 'row',
        height: fullHeight ? '100%' : null,
        width: fullWidth ? '100%' : null,
        flexWrap: wrap ? 'wrap' : null,
      }}
      className={classes}
    >
      {children}
    </div>
  )
})

const VStack = forwardRef((props, ref) => {
  return <Stack {...props} direction="vertical" ref={ref} />
})
const HStack = forwardRef((props, ref) => {
  return (
    <Stack
      classes={`hstack ${props.responsive ? 'hstack--responsive' : ''}`}
      {...props}
      ref={ref}
    />
  )
})

const Spacer = () => {
  return (
    <>
      <div style={{flexGrow: '1'}}></div>
    </>
  )
}

export {Stack, VStack, HStack, Spacer}
