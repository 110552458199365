import Link from 'next/link'
import {useContext, useEffect, useState} from 'react'
import {VStack} from '@components/Stack'
import {Text} from '@components/Text'
import styles from './StreaksWidget.module.css'
import {motion} from 'framer-motion'
import {GlobalContext} from 'src/contexts'
import {CountDown} from 'src/CountDown'
import {Icon} from 'src/Icon'
import {Panel} from './Panel'
import {Button} from 'src/Button'
import {useUser} from 'src/hooks/useUser'
import {saveProfile} from 'src/util/db'
import {sendEvent} from 'src/analytics'
import {toast} from 'react-toastify'

const MText = motion(Text)

export const StreaksWidget = () => {
  const {streak} = useContext(GlobalContext)
  const [showTimer, setShowTimer] = useState(false)

  const user = useUser()
  const [isSaving, setIsSaving] = useState()

  const [tomorrow, setTomorrow] = useState()
  useEffect(() => {
    // calculate remaining time for this timezone's day
    const d = new Date()
    d.setDate(d.getDate() + 1)
    d.setHours(0, 0, 0, 0)
    setTomorrow(d)
  }, [])

  function reminderButtonHandler() {
    setIsSaving(true)
    sendEvent({name: 'streakMailNotifications', label: 1})
    saveProfile(user.uid, {
      'settings.streakMailNotifications': true,
    }).then(() => {
      setIsSaving(false)
      toast.success(
        `Reminder set! You can change this from "Settings" anytime.`
      )
    })
  }

  function shouldShowReminderPopup() {
    return user && !user.settings?.streakMailNotifications && isSaving !== false
  }

  if (isNaN(streak?.count)) return null

  return (
    <motion.div
      className={styles.widget}
      initial={{y: -100}}
      animate={{y: 0}}
      onMouseOver={() => setShowTimer(true)}
      onMouseOut={() => setShowTimer(false)}
    >
      {!!streak?.count && <img className={styles.rays} />}

      <Link href="/leaderboard/streak">
        <a className={styles.widgetMain}>
          <svg
            width="103"
            height="110"
            viewBox="0 0 103 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.bg}
          >
            <g clipPath="url(#clip0_974_2969)">
              <g filter="url(#filter0_di_974_2969)">
                <path
                  d="M0 0H103V90.7685C103 94.4326 100.511 97.6284 96.958 98.5252L55.416 109.011C52.8456 109.66 50.1544 109.66 47.584 109.011L6.042 98.5252C2.4893 97.6284 0 94.4326 0 90.7685V0Z"
                  fill="url(#paint0_linear_974_2969)"
                />
              </g>
            </g>
            <defs>
              <filter
                id="filter0_di_974_2969"
                x="-60"
                y="-30"
                width="223"
                height="221.498"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="1" dy="1" />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite
                  in2="hardAlpha"
                  operator="arithmetic"
                  k2="-1"
                  k3="1"
                />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
                />
                <feBlend
                  mode="normal"
                  in2="shape"
                  result="effect1_innerShadow_974_2969"
                />
              </filter>
              <linearGradient
                id="paint0_linear_974_2969"
                x1="-40"
                y1="-31"
                x2="101"
                y2="106"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="var(--streak-widget-clr-1)" />
                <stop offset="1" stopColor="var(--streak-widget-clr-2)" />
              </linearGradient>
              <clipPath id="clip0_974_2969">
                <rect width="103" height="110" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <div className={styles.widgetShineBig}></div>
          <div className={styles.widgetShineSmall}></div>

          <div className={styles.widgetText}>
            <VStack gap="0.4rem">
              <Icon
                name={streak?.hasPlayedToday ? 'tick-round' : 'clock'}
                color={
                  streak?.hasPlayedToday
                    ? 'var(--clr-green-bright)'
                    : 'var(--clr-text-lightest-final)'
                }
                size="14"
                marginRight="0"
              />

              <VStack gap="0rem">
                {showTimer && !streak?.hasPlayedToday ? null : (
                  <MText
                    size={3}
                    weight="700"
                    appearance="primary"
                    mono={false}
                    lineHeight="1"
                    animate={{opacity: 1}}
                    initial={{opacity: 0}}
                    exit={{opacity: 0}}
                  >
                    {streak?.count}
                  </MText>
                )}
                {showTimer && !streak?.hasPlayedToday ? (
                  <VStack gap="0rem">
                    <MText
                      size={0}
                      weight="600"
                      appearance="tertiary"
                      mono={false}
                      animate={{y: 0, opacity: 1}}
                      initial={{y: -10, opacity: 0}}
                      exit={{y: -10, opacity: 0}}
                      transition={{delay: 0.1}}
                    >
                      {tomorrow ? (
                        <CountDown
                          date={tomorrow}
                          minimal
                          suffix=""
                          showEmoji={false}
                          showUnits={false}
                        />
                      ) : null}
                    </MText>
                    <MText
                      appearance="tertiary"
                      size={0}
                      animate={{y: 0, opacity: 1}}
                      initial={{y: -10, opacity: 0}}
                      exit={{y: -10, opacity: 0}}
                      transition={{delay: 0.2}}
                    >
                      left
                    </MText>
                  </VStack>
                ) : (
                  <MText
                    size={0}
                    weight="600"
                    appearance="tertiary"
                    mono={false}
                    animate={{y: 0, opacity: 1}}
                    initial={{y: 10, opacity: 0}}
                    exit={{y: 10, opacity: 0}}
                    transition={{delay: 0.1}}
                  >
                    day streak
                  </MText>
                )}
              </VStack>
            </VStack>
          </div>
        </a>
      </Link>

      {/* <div className={styles.rays} /> */}

      {shouldShowReminderPopup() && streak?.count > 0 ? (
        <div className={styles.reminderPopup}>
          <Panel padding="1.5rem">
            <VStack>
              <Text appearance="primary" weight="600" size="2" mono={false}>
                Streak reminders
              </Text>

              <Text
                appearance="secondary"
                weight="500"
                size="1"
                align="center"
                mono={false}
              >
                Don't wanna break your streak? Get reminded via email 4 hrs
                before your streak is about to break
              </Text>

              <Button
                size="full-width"
                onClick={reminderButtonHandler}
                isLoading={isSaving}
                primary
              >
                {!isSaving ? 'Enable reminders' : 'Enabling...'}
              </Button>
            </VStack>
          </Panel>{' '}
        </div>
      ) : null}

      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
    </motion.div>
  )
}
