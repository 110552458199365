import {Command} from 'cmdk'
import {useUser} from 'src/hooks/useUser'
import {useState, useEffect} from 'react'
import {useRouter} from 'next/router'
import {ProBadge} from 'src/ProBadge'
import {Icon} from 'src/Icon'
import styles from 'src/CommandPalette.module.css'
import levels from '@cssbattle/shared/levels'
import {battles} from '@cssbattle/shared/battles'

export function CommandPalette({isOpen, onOpenChange, onRequestClose}) {
  const [pages, setPages] = useState([])
  const [search, setSearch] = useState('')
  const page = pages[pages.length - 1]
  const user = useUser()
  const router = useRouter()

  function navigateToRoute(path) {
    router.push(path)
    setSearch('')
    onRequestClose()
  }

  function navigateToRandomTarget() {
    const level = levels[Math.floor(Math.random() * levels.length)]
    navigateToRoute(`/play/${level.id}`)
  }

  function openInNewTab(url) {
    window.open(url, '_blank')
    setSearch('')
    onRequestClose()
  }

  function escapeHandler(event) {
    if (event.key === 'Escape' && pages.length) {
      event.preventDefault()
      setPages((prev) => prev.slice(0, -1))
      setSearch('')
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setSearch('')
      setPages([]) // reset palette when closed
    }
  }, [isOpen])

  return (
    <Command.Dialog
      className={styles.dialog}
      open={isOpen}
      onOpenChange={onOpenChange}
      label="Command Menu"
      onKeyDown={escapeHandler}
    >
      <Command.Input
        value={search}
        onValueChange={setSearch}
        placeholder="Search anything here..."
      />
      <Command.List>
        <Command.Empty>No results found.</Command.Empty>
        {!page && (
          <>
            {user && (
              <>
                <Command.Item
                  onSelect={() => {
                    navigateToRoute('/me')
                  }}
                >
                  My analytics
                </Command.Item>
                <Command.Separator />
              </>
            )}
            <Command.Group heading="Help">
              <Command.Item onSelect={() => navigateToRoute('/tips')}>
                Tips & Tricks
              </Command.Item>
              <Command.Item onSelect={() => navigateToRoute('/learn')}>
                Learn CSS
              </Command.Item>
              <Command.Item onSelect={() => navigateToRoute('/faqs')}>
                FAQs
              </Command.Item>
            </Command.Group>
            <Command.Separator />
            <Command.Item
              onSelect={() => {
                navigateToRoute('/daily')
              }}
            >
              Daily targets
            </Command.Item>
            <Command.Item
              onSelect={() => {
                navigateToRoute('/leaderboard')
              }}
            >
              Leaderboard
            </Command.Item>
            <Command.Item onSelect={navigateToRandomTarget}>
              I am feeling lucky 🎯
            </Command.Item>

            {!user?.isPro && (
              <Command.Item onSelect={() => navigateToRoute('/plus')}>
                Upgrade to <ProBadge style={{marginLeft: '0.5rem'}} />
              </Command.Item>
            )}

            {user && (
              <>
                <Command.Item onSelect={() => navigateToRoute('/settings')}>
                  Settings
                </Command.Item>
                <Command.Item
                  onSelect={() =>
                    navigateToRoute(`/player/${user.username || user.uid}`)
                  }
                >
                  My profile
                </Command.Item>
              </>
            )}
            <Command.Item onSelect={() => navigateToRoute('/')}>
              Home
            </Command.Item>
            <Command.Item onSelect={() => navigateToRoute('/blog')}>
              Blog
            </Command.Item>
            <Command.Separator />
            <Command.Group heading="Community">
              <Command.Item
                onSelect={() => {
                  openInNewTab('https://community.cssbattle.dev')
                }}
              >
                Community
              </Command.Item>
              <Command.Item
                onSelect={() => {
                  openInNewTab('https://discord.gg/rvqvbcFM86')
                }}
              >
                Join on Discord <Icon name="discord" size="18" />
              </Command.Item>
            </Command.Group>
            <Command.Separator />
            <Command.Item
              onSelect={() => {
                setPages([...pages, 'targets'])
                setSearch('')
              }}
            >
              Play target ▶
            </Command.Item>
            <Command.Item
              onSelect={() => {
                setPages([...pages, 'battles'])
                setSearch('')
              }}
            >
              Battles ▶
            </Command.Item>
            <Command.Separator />
            <Command.Item
              onSelect={() => {
                openInNewTab('https://github.com/cssbattle/forum/issues')
              }}
            >
              Report a bug
            </Command.Item>

            <Command.Item onSelect={() => navigateToRoute('/changelog')}>
              Changelog
            </Command.Item>

            {search
              ? levels.map((level) => (
                  <Command.Item
                    key={level.id}
                    onSelect={() => {
                      navigateToRoute(`/play/${level.id}`)
                      setPages([])
                    }}
                  >
                    Play target #{level.id} - {level.name}
                  </Command.Item>
                ))
              : null}

            {search
              ? battles.map((battle) => (
                  <Command.Item
                    key={battle.id}
                    onSelect={() => {
                      navigateToRoute(`/battle/${battle.id}`)
                      setPages([])
                    }}
                  >
                    Battle #{battle.id} - {battle.name}
                  </Command.Item>
                ))
              : null}
          </>
        )}

        {page === 'targets' && (
          <Command.Group heading="Play target ▼">
            {levels.map((level) => (
              <Command.Item
                key={level.id}
                onSelect={() => {
                  navigateToRoute(`/play/${level.id}`)
                  setPages([])
                }}
              >
                Play target #{level.id} - {level.name}
              </Command.Item>
            ))}
          </Command.Group>
        )}
        {page === 'battles' && (
          <Command.Group heading="Battles ▼">
            {battles.map((battle) => (
              <Command.Item
                key={battle.id}
                onSelect={() => {
                  navigateToRoute(`/battle/${battle.id}`)
                  setPages([])
                }}
              >
                Battle #{battle.id} - {battle.name}
              </Command.Item>
            ))}
          </Command.Group>
        )}
      </Command.List>
    </Command.Dialog>
  )
}
