export const CircularProgressBar = ({
  size = 20,
  thickness = 3,
  percent = 75,
}) => {
  const radius = size / 2 - thickness
  const circumference = 2 * radius * 3.141
  const offset = circumference * ((100 - percent) / 100)

  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 ' + size + ' ' + size}
      style={{transform: 'rotate(-90deg)'}}
    >
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke="var(--clr-border-brighter)"
        strokeWidth={thickness + 'px'}
      ></circle>

      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke="var(--clr-green-bright)"
        strokeWidth={thickness + 'px'}
        strokeDasharray={circumference + 'px'}
        strokeDashoffset={offset + 'px'}
        strokeLinecap="round"
      ></circle>
    </svg>
  )
}
