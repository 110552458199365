import {useState, useEffect} from 'react'

export const LivePlayerCounter = () => {
  const [liveCount, setLiveCount] = useState(0)
  const [liveCountEmoji, setLiveCountEmoji] = useState('🙂')

  useEffect(() => {
    async function getOnlinePlayerCount() {
      if (document.visibilityState !== 'visible') return
      const res = await fetch('/api/session')
      const count = parseInt(await res.text(), 10)
      if (count) {
        setLiveCount(count)
      }
    }

    const id = window.setInterval(getOnlinePlayerCount, 15000)
    getOnlinePlayerCount()

    return () => {
      clearInterval(id)
    }
  }, [])

  useEffect(() => {
    if (liveCount >= 400) {
      setLiveCountEmoji('🔥')
    }
    if (liveCount < 400) {
      setLiveCountEmoji('🤯')
    }
    if (liveCount < 350) {
      setLiveCountEmoji('🤩')
    }
    if (liveCount < 250) {
      setLiveCountEmoji('😀')
    }
    if (liveCount < 150) {
      setLiveCountEmoji('🙂')
    }
  }, [liveCount])

  return (
    <div className="header__live-counter">
      <div className="header__live-counter-pulse"></div>
      <span className="header__live-counter-number">{liveCount}</span> Online
    </div>
  )
}
