import {Button} from 'src/Button'
import Link from 'next/link'

export const GlowButton = ({fullWidth = false, href, onClick, children}) => {
  let tag = Button
  if (href) {
    tag = href.match(/http/) ? 'a' : Link
  }

  const className = `button button--glow ${
    fullWidth ? 'button--full-width' : ''
  }`

  if (tag === 'a') {
    return (
      <a href={href} className={className}>
        {children}
        <span className="button__border"></span>
      </a>
    )
  } else if (tag === Link) {
    return (
      <Link href={href}>
        <a className={className}>
          {children}
          <span className="button__border"></span>
        </a>
      </Link>
    )
  } else {
    return (
      <Button onClick={onClick} className={className}>
        {children}
        <span className="button__border"></span>
      </Button>
    )
  }
}
