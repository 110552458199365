import React from 'react'
import {Loader} from './Loader'

export function Button({
  type = 'button',
  primary,
  danger,
  success,
  cool,
  size,
  extraClasses = '',
  children,
  isLoading,
  ...rest
}) {
  return (
    <button
      type={type}
      className={`button ${primary ? 'button--primary' : ''} 
      ${cool ? 'button--cssbattle' : ''}
      ${size ? `button--${size}` : ''}
      ${success ? 'button--success' : ''} ${
        danger ? 'button--danger' : ''
      } ${extraClasses}`}
      disabled={isLoading}
      {...rest}
    >
      {children}
      <div
        style={{
          display: 'flex',
          transition: 'max-width 0.3s ease',
          overflow: 'hidden',
          maxWidth: isLoading ? '1.6em' : 0,
        }}
      >
        <Loader height="1.2em" leftMargin="0.5rem" />
      </div>
    </button>
  )
}
