import {useEffect, useState, useContext} from 'react'
import {Header} from './Header'
import {Footer} from './Footer'
import {CSSBattleSidebar} from '@components/CSSBattleSidebar'
import {useRouter} from 'next/router'
import {GlobalContext} from './contexts'
import {isMobile} from './utils'

export function Page({children}) {
  const router = useRouter()
  const isPlayPage = /\/(play\/\S*)|(learn\/\w+\/\d+)|(course-editor\S*)/.test(
    router.asPath
  )

  const [isSidebarVisible, setIsSidebarVisible] = useState(
    isPlayPage || isMobile() ? false : true
  )

  const toggleSidebarVisibility = () => {
    setIsSidebarVisible(!isSidebarVisible)
  }

  useEffect(() => {
    setIsSidebarVisible(isPlayPage || isMobile() ? false : isSidebarVisible)
  }, [router.asPath])

  const {levelId, level, battle, setBattle} = useContext(GlobalContext)

  return (
    <>
      <div className="page-wrapper">
        <CSSBattleSidebar
          collapsible={isPlayPage || isMobile()}
          visible={isSidebarVisible}
        />

        <div className="content-wrapper">
          <Header
            onToggleSidebar={toggleSidebarVisibility}
            isSidebarVisible={isSidebarVisible}
            levelId={levelId}
            level={level}
            battle={battle}
          />
          <div
            className={`page-content ${
              isPlayPage && 'page-content--no-padding'
            }`}
          >
            <div
              className={`page-content__inner ${
                isPlayPage && 'page-content__inner--fluid'
              }`}
            >
              {children}
              <div
                className={`${
                  isPlayPage && isSidebarVisible
                    ? 'sidebar-container--visible'
                    : ''
                }`}
              >
                <div
                  className="underlay"
                  onClick={toggleSidebarVisibility}
                ></div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </>
  )
}
