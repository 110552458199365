import {useState, useEffect} from 'react'

function useSubmitKeySymbol() {
  const [submitKeySymbol, setSubmitKeySymbol] = useState('')
  useEffect(() => {
    setSubmitKeySymbol(navigator.userAgent.includes('Mac OS X') ? '⌘' : 'Ctrl')
  }, [])

  return submitKeySymbol
}

export {useSubmitKeySymbol}
