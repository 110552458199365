import {useEffect} from 'react'

async function add() {
  const response = await fetch('/api/session?action=add')
  const data = await response.text()
  return data
}
function isBot() {
  var botPattern =
    '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
  var re = new RegExp(botPattern, 'i')
  var userAgent = navigator.userAgent
  return re.test(userAgent)
}
const SESSION_TIME = 60 // seconds

export function useSessionTrack() {
  // send a session add ping once during a browser session and on unload, we send a
  // session remove ping using beacon
  useEffect(() => {
    return false
    let sessionId
    let intervalId

    if (isBot()) return

    if (window.sessionStorage.getItem('cssbattleSessionLogged')) return
    window.sessionStorage.setItem('cssbattleSessionLogged', true)

    function addSession() {
      add().then((id) => (sessionId = id))
      // session window is limited. So keep updating session every session interval.
      // We do after 5 seconds extra just to keep a margin between old and new session
      intervalId = setInterval(() => {
        add().then((id) => (sessionId = id))
      }, (SESSION_TIME + 5) * 1000)
    }

    addSession()

    // firefox and safari don't fire `visibilitychange` event on closing tab.
    // So use `unload` event for them.
    // if (navigator.vendor === '' || navigator.vendor.match(/Apple/)) {
    window.addEventListener('beforeunload', () => {
      navigator.sendBeacon(`/api/session?action=remove&sessionId=${sessionId}`)
    })
    // }
    /*  document.addEventListener('visibilitychange', (e) => {
      if (document.visibilityState === 'visible') {
        addSession()
      } else {
        clearInterval(intervalId)
        navigator.sendBeacon(
          `/api/session?action=remove&sessionId=${sessionId}`
        )
      }
    }) */
  }, [])
}
