import {BattleStatuses} from '@cssbattle/shared/battles'

const classes = {
  [BattleStatuses.UPCOMING]: 'pill--warning',
  [BattleStatuses.ONGOING]: 'pill--success',
  [BattleStatuses.FINISHED]: 'pill--danger',
}

const bg = {
  [BattleStatuses.UPCOMING]: '#feda44',
  [BattleStatuses.ONGOING]: '#46d158',
  [BattleStatuses.FINISHED]: '#a3483d',
}
const fg = {
  [BattleStatuses.UPCOMING]: '#000',
  [BattleStatuses.ONGOING]: '#000',
  [BattleStatuses.FINISHED]: '#fff',
}
const label = {
  [BattleStatuses.UPCOMING]: 'Upcoming',
  [BattleStatuses.ONGOING]: 'Ongoing',
  [BattleStatuses.FINISHED]: 'Finished',
}
export function BattleStatus({status, minimal}) {
  return (
    <div
      className={`pill
      ${classes[status]}
      
      ${minimal ? 'battle-status--minimal hint--right' : ''}`}
      aria-label={minimal ? label[status] : ''}
    >
      {minimal ? '' : label[status]}
    </div>
  )
}
